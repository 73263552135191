// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
//require("bootstrap")
import * as bootstrap from 'bootstrap'
import 'bootstrap4-tagsinput/tagsinput.js'


import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";
import NestedForm from "stimulus-rails-nested-form"
import $ from 'jquery'
window.$ = $;

import AttachmentNestedForm from "./attachment_nested_form"

require("trix")
require("@rails/actiontext")
require("moment")
require("daterangepicker")
require("select2")
require("bootstrap-datepicker")
require("sticky-table-headers")
require("./input-mask-phone-number.js")

Rails.start()
Turbolinks.start()
ActiveStorage.start()

window.addEventListener('turbolinks:before-render', function () {
  window.zEACLoaded = undefined;
});

document.addEventListener("turbolinks:load", () => {
  if ($('.height-req-table').length > 0) {
    var OffsetValue = $('.height-req-table').offset().top;
  }
  $('[data-bs-toggle="tooltip"]').tooltip()
  $('[data-bs-toggle="popover"]').popover({html: true, sanitize: false, container: 'body'})
  $('html').on('click', function(e) {
    if (typeof $(e.target).data('original-title') == 'undefined') {
      $('[data-original-title]').popover('hide');
    }
  });

  $('.add-school-btn').on('click', function(e) {
    $('.school-widget').removeClass('d-none');
  });

   $('body').on('keypress', '#user_username', function(e){
    if(e.which === 32){
      return false;
    }
  });

  $('body').on('click', '.js-show-pw-update', function(e){
    $('.password-fields').toggle();
    $(this).remove();
  });  

  $('body').on('click','.edit-school-name', function(e) {
    $('.original-edit').addClass('d-none');
    $('.temp-school-name').addClass('d-none');
    $('.field_for_school').removeClass('d-none');
    $('.edit-school-name-btns').removeClass('d-none');
    e.preventDefault();
  });

  
  $('body').on('keyup','.custom-js-phone', function(e) {
    if ($(this).val().replace(/[_-]/gi, '').length == 10) {
      $(this).siblings('.form-text.text-muted').hide();
      $('.js-review-request-from').attr('disabled', false);
    } else {
      $(this).siblings('.form-text.text-muted').show();
      $('.js-review-request-from').attr('disabled', true);
    }
  });

  $('body').on('keyup','#student_confirm_password', function(e) {
    if (($(this).val().length == 0) || ($("#student_password").val() == $(this).val())) {
      $(this).parent().siblings('.form-text.text-muted').hide();
    } else {
      $(this).parent().siblings('.form-text.text-muted').show();
    }
  });

  $('body').on('keyup', '#request_confirm_password', function(e){
    if ($(this).val() == $('#request_password').val()) {
      $(this).parent().siblings('.form-text.text-muted').hide();
    } else {
      $(this).parent().siblings('.form-text.text-muted').show();
    }
  });

  $('body').on('keyup', '#request_password', function(e){
    if ($(this).val().length >= 8) {
      $(this).parent().siblings('.form-text.text-muted').hide();
    } else {
      $(this).parent().siblings('.form-text.text-muted').show();
    }
  });

  $('body').on('keyup', '#user_profile_attributes_first_name', function(e){
    if ($(this).val().length > 0) {
      $(this).siblings('.form-text.text-muted').hide();
    } else {
      $(this).siblings('.form-text.text-muted').show();
    }
  });

  $('body').on('change', '#user_terms_accepted', function(e){
    if ($(this).is(':checked')) {
      $(this).parent().siblings('.form-text.text-muted').hide();
    } else {
      $(this).parent().siblings('.form-text.text-muted').show();
    }
  });

  $('body').on('keyup', '#user_profile_attributes_last_name', function(e){
    if ($(this).val().length > 0) {
      $(this).siblings('.form-text.text-muted').hide();
    } else {
      $(this).siblings('.form-text.text-muted').show();
    }
  });

  $('body').on('keyup', '#user_profile_attributes_email', function(e){
    if ($(this).val().length > 0) {
      $(this).siblings('.form-text.text-muted').hide();
      let data = new FormData();
      data.append('email', $(this).val());
      Rails.ajax({
        url: '/email_verification_codes/check_email',
        type: "POST",
        data: data,
        success: (data) => {
          if (!data.success) {
            $('#user_profile_attributes_email').siblings('.form-text.text-muted')[0].innerHTML = 'Email already exists.'
            $(this).siblings('.form-text.text-muted').show();
          }
        }
      })
    } else {
      $('#user_profile_attributes_email').siblings('.form-text.text-muted')[0].innerHTML = 'Please enter your email address.'
      $(this).siblings('.form-text.text-muted').show();
    }
  });

  $('body').on('change', '#user_profile_attributes_timezone', function(e){
    if ($(this).val() != '') {
      $(this).siblings('.form-text.text-muted').hide();
    } else {
      $(this).siblings('.form-text.text-muted').show();
    }
  });

  $('body').on('keyup', '#user_username', function(e){
    $(this).val(e.currentTarget.value.toLowerCase());
    if ($(this).val().length > 0) {
      $(this).siblings('.form-text.text-muted').hide();
    } else {
      $(this).siblings('.form-text.text-muted').show();
    }
  });

  $('body').on('change', '.js-checkboxes-required input[type=checkbox]', function (e) {
    if ($('.js-checkboxes-required :checkbox:checked').length == 0) {
      $('.js-for-client-side-course').show();
    } else {
      $('.js-for-client-side-course').hide();
    }
  });

  $('body').on('keyup', '#profile_school_name', function(e){
    if ($(this).val().length > 0) {
      $(this).parent().siblings('.form-text.text-muted.js-for-form-submit').hide();
    } else {
      $(this).parent().siblings('.form-text.text-muted.js-for-form-submit').show();
    }
  });

  $('#js-submit-btn-student').on('click', function(e){
    if ($('#student_terms_accepted').is(':checked')) {
      $('#student_terms_accepted').parent().siblings('.form-text.text-muted').hide();
    } else {
      $('#student_terms_accepted').parent().siblings('.form-text.text-muted').show();
      e.preventDefault();
    }
  })


  $('body').on('keyup','#student_confirm_email', function(e) {
    if (($(this).val().length == 0) || ($("#student_email").val() == $(this).val())) {
      $(this).siblings('.form-text.text-muted').hide();
    } else {
      $(this).siblings('.form-text.text-muted').show();
    }
  });

  $('.cancel-add-school-btn').on('click', function(e) {
    $('.school-widget').addClass('d-none');
    e.preventDefault();
  });

  $('body').on('change', '#search_school_id', function(e){
    $('.submit-edit-school-name').attr('disabled', false);
  });

  $('body').on('change', '#course_request_no_lti_integration', function(e){
    var setRequired = ($(this).val() == '') || ($(this).val() == 'false');
    $('#course_request_date_registration_opens').prop('required', setRequired);
    $('#course_request_date_registration_closes').prop('required', setRequired);
    if (setRequired) {
      if ($('#course_request_date_registration_opens').val().length > 0) {
        $('#course_request_date_registration_opens').parent().siblings('.form-text.text-muted').hide();
      }

      if ($('#course_request_date_registration_closes').val().length > 0) {
        $('#course_request_date_registration_closes').parent().siblings('.form-text.text-muted').hide();
      }
    } else {
      $('#course_request_date_registration_opens').parent().siblings('.form-text.text-muted').hide();
      $('#course_request_date_registration_closes').parent().siblings('.form-text.text-muted').hide();
    }
    $('.js-lti-date-modify-labels').toggleClass('required', setRequired);
  });

  $('body').on('click','.submit-edit-school-name', function(e) {
    $(this).attr('disabled', true);
    var schoolId = $('#search_school_id').val();
    var url = 'update_school.js';
    $.ajax(url, { type: 'PATCH', data: { school_id: schoolId } });
  });

  $('.cancel-edit-school-name').on('click', function(e) {
    $('.original-edit').removeClass('d-none');
    $('.temp-school-name').removeClass('d-none');
    $('.field_for_school').addClass('d-none');
    $('.edit-school-name-btns').addClass('d-none');
    e.preventDefault();
  });

  $('body').on('change', '.js-status-index', function(){
    if ($(this).data('active-statuses').indexOf($(this).val()) > -1) {
      $('.js-active-index[data-id="' + $(this).data('id') + '"]').attr('disabled', false);
    } else {
      $('.js-active-index[data-id="' + $(this).data('id') + '"]').attr('disabled', true);
    }
    this.title = $(this).val();
  });

  $('.js-status-index').each(function(){
    this.title = $(this).val();
  })

  $('body').on('click', '.help-link', function(e){
    e.preventDefault();
  });

  $('body').on('change', '#course_request_status', function(e){
    if ($(this).data('active-statuses').indexOf($(this).val()) > -1) {
      $('#course_request_active').attr('disabled', false);
      $('.help-link').hide();
    } else {
      $('#course_request_active').attr('disabled', true);
      $('.help-link').show();
    }
  });

  if (($('.new_student').length > 0) || ($('#js-form-course-request').length > 0)) {
    $('.form-text.text-muted').addClass('invalid-feedback-custom')
    $('.form-text.text-muted').hide();
    if ($('.new_student').length > 0) {
      $('.input-group-append').hide();
    }
  }

  $('body').on('keyup', '#request_password', function(){
    if ($(this).val().length > 0) {
      $(this).siblings('.input-group-append').show();
      if ($(this).attr('type') == 'text') {
        $('#req-password-visibility-true').addClass("d-none");
        $('#req-password-visibility-false').removeClass("d-none");
      } else {
        $('#req-password-visibility-true').removeClass("d-none");
        $('#req-password-visibility-false').addClass("d-none");
      }
    } else {
      $(this).siblings('.input-group-append').hide();
      $('#req-password-visibility-true').addClass("d-none");
      $('#req-password-visibility-false').addClass("d-none");
    }
  });

  $('#req-password-visibility-true').click(function(e){
    e.preventDefault();
    $('#request_password').attr('type', 'text');
    $('#req-password-visibility-true').addClass("d-none");
    $('#req-password-visibility-false').removeClass("d-none");
  });

  $('#req-password-visibility-false').click(function(e){
    e.preventDefault();
    $('#request_password').attr('type', 'password');
    $('#req-password-visibility-false').addClass("d-none");
    $('#req-password-visibility-true').removeClass("d-none");
  });

  $('body').on('keyup', '#request_confirm_password', function(){
    if ($(this).val().length > 0) {
      $(this).siblings('.input-group-append').show();
      if ($(this).attr('type') == 'text') {
        $('#req-c-password-visibility-true').addClass("d-none");
        $('#req-c-password-visibility-false').removeClass("d-none");
      } else {
        $('#req-c-password-visibility-true').removeClass("d-none");
        $('#req-c-password-visibility-false').addClass("d-none");
      }
    } else {
      $(this).siblings('.input-group-append').hide();
      $('#req-c-password-visibility-true').addClass("d-none");
      $('#req-c-password-visibility-false').addClass("d-none");
    }
  });

  $('#req-c-password-visibility-true').click(function(e){
    e.preventDefault();
    $('#request_confirm_password').attr('type', 'text');
    $('#req-c-password-visibility-true').addClass("d-none");
    $('#req-c-password-visibility-false').removeClass("d-none");
  });

  $('#req-c-password-visibility-false').click(function(e){
    e.preventDefault();
    $('#request_confirm_password').attr('type', 'password');
    $('#req-c-password-visibility-false').addClass("d-none");
    $('#req-c-password-visibility-true').removeClass("d-none");
  });

  $('body').on('keyup', '#user_password', function(){
    if ($(this).val().length > 0) {
      $(this).siblings('.input-group-append').show();
      if ($(this).attr('type') == 'text') {
        $('#login-password-visibility-true').addClass("d-none");
        $('#login-password-visibility-false').removeClass("d-none");
      } else {
        $('#login-password-visibility-true').removeClass("d-none");
        $('#login-password-visibility-false').addClass("d-none");
      }
    } else {
      $(this).siblings('.input-group-append').hide();
      $('#login-password-visibility-true').addClass("d-none");
      $('#login-password-visibility-false').addClass("d-none");
    }
  });

  $('#login-password-visibility-true').click(function(e){
    e.preventDefault();
    $('#user_password').attr('type', 'text');
    $('#login-password-visibility-true').addClass("d-none");
    $('#login-password-visibility-false').removeClass("d-none");
  });

  $('#login-password-visibility-false').click(function(e){
    e.preventDefault();
    $('#user_password').attr('type', 'password');
    $('#login-password-visibility-false').addClass("d-none");
    $('#login-password-visibility-true').removeClass("d-none");
  });

  $('#password-visibility-true').click(function(e){
    e.preventDefault();
    $('#student_password').attr('type', 'text');
    $('#password-visibility-true').addClass("d-none");
    $('#password-visibility-false').removeClass("d-none");
  });

  $('#password-visibility-false').click(function(e){
    e.preventDefault();
    $('#student_password').attr('type', 'password');
    $('#password-visibility-false').addClass("d-none");
    $('#password-visibility-true').removeClass("d-none");
  });

  $('#c-password-visibility-true').click(function(e){
    e.preventDefault();
    $('#student_confirm_password').attr('type', 'text');
    $('#c-password-visibility-true').addClass("d-none");
    $('#c-password-visibility-false').removeClass("d-none");
  });

  $('#c-password-visibility-false').click(function(e){
    e.preventDefault();
    $('#student_confirm_password').attr('type', 'password');
    $('#c-password-visibility-false').addClass("d-none");
    $('#c-password-visibility-true').removeClass("d-none");
  });

  $('#forgot-password-visibility-true').click(function(e){
    e.preventDefault();
    $('#forgot-password').attr('type', 'text');
    $('#forgot-password-visibility-true').addClass("d-none");
    $('#forgot-password-visibility-false').removeClass("d-none");
  });

  $('#forgot-password-visibility-false').click(function(e){
    e.preventDefault();
    $('#forgot-password').attr('type', 'password');
    $('#forgot-password-visibility-false').addClass("d-none");
    $('#forgot-password-visibility-true').removeClass("d-none");
  });

  $('#forgot-confirm-password-visibility-true').click(function(e){
    e.preventDefault();
    $('#forgot-confirm-password').attr('type', 'text');
    $('#forgot-confirm-password-visibility-true').addClass("d-none");
    $('#forgot-confirm-password-visibility-false').removeClass("d-none");
  });

  $('#forgot-confirm-password-visibility-false').click(function(e){
    e.preventDefault();
    $('#forgot-confirm-password').attr('type', 'password');
    $('#forgot-confirm-password-visibility-false').addClass("d-none");
    $('#forgot-confirm-password-visibility-true').removeClass("d-none");
  });

  $('#profile-password-visibility-true').click(function(e){
    e.preventDefault();
    $('#profile-password').attr('type', 'text');
    $('#profile-password-visibility-true').addClass("d-none");
    $('#profile-password-visibility-false').removeClass("d-none");
  });

  $('#profile-password-visibility-false').click(function(e){
    e.preventDefault();
    $('#profile-password').attr('type', 'password');
    $('#profile-password-visibility-false').addClass("d-none");
    $('#profile-password-visibility-true').removeClass("d-none");
  });

  $('#profile-confirm-password-visibility-true').click(function(e){
    e.preventDefault();
    $('#profile-confirm-password').attr('type', 'text');
    $('#profile-confirm-password-visibility-true').addClass("d-none");
    $('#profile-confirm-password-visibility-false').removeClass("d-none");
  });

  $('#profile-confirm-password-visibility-false').click(function(e){
    e.preventDefault();
    $('#profile-confirm-password').attr('type', 'password');
    $('#profile-confirm-password-visibility-false').addClass("d-none");
    $('#profile-confirm-password-visibility-true').removeClass("d-none");
  });

  $('#profile-c-password-visibility-true').click(function(e){
    e.preventDefault();
    $('#profile-c-password').attr('type', 'text');
    $('#profile-c-password-visibility-true').addClass("d-none");
    $('#profile-c-password-visibility-false').removeClass("d-none");
  });

  $('#profile-c-password-visibility-false').click(function(e){
    e.preventDefault();
    $('#profile-c-password').attr('type', 'password');
    $('#profile-c-password-visibility-false').addClass("d-none");
    $('#profile-c-password-visibility-true').removeClass("d-none");
  });

  if ($('.edit_profile').length > 0) {
    $('#profile-password').parent().siblings('.form-text.text-muted').addClass('invalid-feedback-custom');
  }

  if (($('.new_user').length > 0) && ($('#forgot-password').length > 0)) {
    $('#forgot-password').parent().siblings('.form-text.text-muted').addClass('invalid-feedback-custom');
  }

  $('body').on('keyup', '#profile-password', function(e){
    if ($(this).val().length >= 8) {
      $(this).parent().siblings('.form-text.text-muted').hide();
    } else {
      $(this).parent().siblings('.form-text.text-muted').show();
    }
  });

  if ($('.new_user').length > 0) {
    $('.custom-js-phone').siblings('.form-text.text-muted').addClass('invalid-feedback-custom');
    $('.custom-js-phone').siblings('.form-text.text-muted').hide();
    $('#request_confirm_password').parent().siblings('.form-text.text-muted').addClass('invalid-feedback-custom');
    $('#request_confirm_password').parent().siblings('.form-text.text-muted').hide();
    $('#request_password').parent().siblings('.form-text.text-muted').addClass('invalid-feedback-custom');
    // $('#request_password').parent().siblings('.form-text.text-muted').hide();
    $('#user_terms_accepted').parent().siblings('.form-text.text-muted').addClass('invalid-feedback-custom');
    $('#user_terms_accepted').parent().siblings('.form-text.text-muted').hide();
    $('#user_profile_attributes_first_name').siblings('.form-text.text-muted').addClass('invalid-feedback-custom');
    $('#user_profile_attributes_first_name').siblings('.form-text.text-muted').hide();
    $('.js-for-client-side-course').hide();
    $('#user_profile_attributes_last_name').siblings('.form-text.text-muted').addClass('invalid-feedback-custom');
    $('#user_profile_attributes_last_name').siblings('.form-text.text-muted').hide();
    $('#user_profile_attributes_email').siblings('.form-text.text-muted').addClass('invalid-feedback-custom');
    $('#user_profile_attributes_email').siblings('.form-text.text-muted').hide();
    $('#user_profile_attributes_timezone').siblings('.form-text.text-muted').addClass('invalid-feedback-custom');
    $('#user_profile_attributes_timezone').siblings('.form-text.text-muted').hide();
    $('#user_username').siblings('.form-text.text-muted').addClass('invalid-feedback-custom');
    $('#user_username').siblings('.form-text.text-muted').hide();
    $('#profile_school_name').parent().siblings('.form-text.text-muted.js-for-form-submit').addClass('invalid-feedback-custom');
    $('#profile_school_name').parent().siblings('.form-text.text-muted.js-for-form-submit').hide();
  }

  $('body').on('submit', '#new_user', function(e){
    $('#request_confirm_password').parent().siblings('.form-text.text-muted').hide();
    $('#request_password').parent().siblings('.form-text.text-muted').hide();
    $('#profile_school_name').parent().siblings('.form-text.text-muted.js-for-form-submit').hide();
    $('#user_profile_attributes_first_name').siblings('.form-text.text-muted').hide();
    $('.js-for-client-side-course').hide();
    $('#user_terms_accepted').parent().siblings('.form-text.text-muted').hide();
    $('#user_profile_attributes_last_name').siblings('.form-text.text-muted').hide();
    $('#user_profile_attributes_email').siblings('.form-text.text-muted').hide();
    $('#user_profile_attributes_timezone').siblings('.form-text.text-muted').hide();
    $('#user_username').siblings('.form-text.text-muted').hide();
    
    var disableBtn = false;

    if ($('#user_profile_attributes_first_name').val() == '') {
      if (!disableBtn){
        scrollToPoint($('#user_profile_attributes_first_name'))
      }
      disableBtn = true;
      $('#user_profile_attributes_first_name').siblings('.form-text.text-muted').show();
    }
    
    if ($('#user_profile_attributes_last_name').val() == '') {
      if (!disableBtn){
        scrollToPoint($('#user_profile_attributes_last_name'))
      }
      disableBtn = true;
      $('#user_profile_attributes_last_name').siblings('.form-text.text-muted').show();
    }

    if ($('#user_profile_attributes_email').val() == '') {
      if (!disableBtn){
        scrollToPoint($('#user_profile_attributes_email'))
      }
      disableBtn = true;
      $('#user_profile_attributes_email').siblings('.form-text.text-muted').show();
    }

    if ($('#profile_school_name').val() == '') {
      if (!disableBtn){
        scrollToPoint($('#profile_school_name'))
      }
      disableBtn = true;
      $('#profile_school_name').parent().siblings('.form-text.text-muted.js-for-form-submit').show();
    }
    
    if ($('#user_profile_attributes_timezone').val() == '') {
      if (!disableBtn){
        scrollToPoint($('#user_profile_attributes_timezone'))
      }
      disableBtn = true;
      $('#user_profile_attributes_timezone').siblings('.form-text.text-muted').show();
    }

    if ($('.js-checkboxes-required :checkbox:checked').length == 0) {
      if (!disableBtn){
        scrollToPoint($('.js-checkboxes-required'))
      }
      disableBtn = true;
      $('.js-for-client-side-course').show();
    }

    if ($('#user_username').val() == '') {
      if (!disableBtn){
        scrollToPoint($('#user_username'))
      }
      disableBtn = true;
      $('#user_username').siblings('.form-text.text-muted').show();
    }

    if (($('#request_password').val().length < 8)) {
      $('#request_password').parent().siblings('.form-text.text-muted').show();
      if (!disableBtn){
        scrollToPoint($('#request_password'))
      }
      disableBtn = true;
    }

    if (($('#request_confirm_password').val() == '') || ($('#request_confirm_password').val() != $('#request_password').val())) {
      $('#request_confirm_password').parent().siblings('.form-text.text-muted').show();
      if (!disableBtn){
        scrollToPoint($('#request_confirm_password'))
      }
      disableBtn = true;
    }

    if (!$('#user_terms_accepted').is(':checked')) {
      if (!disableBtn){
        scrollToPoint($('#user_terms_accepted'))
      }
      disableBtn = true;
      $('#user_terms_accepted').parent().siblings('.form-text.text-muted').show();
    }
    if (disableBtn) {
      e.preventDefault(e);
      return false;
    }
  });

  $('body').on('keyup', '#course_request_prefix, #course_request_number, #course_request_title', function(e){
    if ($(this).val().length > 0) {
      $(this).parent().siblings('.form-text.text-muted').hide();
    } else {
      $(this).parent().siblings('.form-text.text-muted').show();
    }
  });

  $('body').on('change keyup', '#course_request_estimated_enrollment', function(e){
    if ($(this).val().length > 0) {
      $(this).parent().siblings('.form-text.text-muted').hide();
    } else {
      $(this).parent().siblings('.form-text.text-muted').show();
    }
  });

  $('body').on('keyup', '.bootstrap-tagsinput input', function(e){
    if (($('.bootstrap-tagsinput input').val().length > 0) || ($('#course_request_section').val().length > 0)) {
      $(this).parent().siblings('.form-text.text-muted').hide();
    } else {
      $(this).parent().siblings('.form-text.text-muted').show();
    }
  });

  $('body').on('keyup', '#course_request_section', function(e){
    if ($('#course_request_section').val().length > 0) {
      $(this).parent().siblings('.form-text.text-muted').hide();
    } else {
      $(this).parent().siblings('.form-text.text-muted').show();
    }
  });

  $('body').on('keyup', '#course_request_date_of_midterm', function(e){
    if ($('#course_request_no_midterm').is(':checked') || $('#course_request_midterm_open').is(':checked') || ($('#course_request_date_of_midterm').val().length > 0)) {
      $('.midterm-exam-error').hide();
    } else {
      $('.midterm-exam-error').show();
    }
  });

  $('body').on('change', '#course_request_no_midterm, #course_request_midterm_open', function(e){
    if ($('#course_request_no_midterm').is(':checked') || $('#course_request_midterm_open').is(':checked') || ($('#course_request_date_of_midterm').val().length > 0)) {
      $('.midterm-exam-error').hide();
    } else {
      $('.midterm-exam-error').show();
    }
  });

  $('body').on('keyup', '#course_request_date_of_final', function(e){
    if ($('#course_request_no_final').is(':checked') || $('#course_request_final_open').is(':checked') || ($('#course_request_date_of_final').val().length > 0)) {
      $('.final-exam-error').hide();
    } else {
      $('.final-exam-error').show();
    }
  });

  $('body').on('change', '#course_request_no_final, #course_request_final_open', function(e){
    if ($('#course_request_no_final').is(':checked') || $('#course_request_final_open').is(':checked') || ($('#course_request_date_of_final').val().length > 0)) {
      $('.final-exam-error').hide();
    } else {
      $('.final-exam-error').show();
    }
  });

  $('body').on('change', '#course_request_date_registration_opens, #course_request_date_registration_closes, #course_request_date_of_first_class, #course_request_date_of_last_class, #course_request_school_lms, #course_request_lti_integration, #course_request_semester_id, #course_request_course_id, #course_request_calendar_option, #course_request_no_lti_integration', function(e){
    if ($(this).val().length > 0) {
      $(this).parent().siblings('.form-text.text-muted').hide();
    } else {
      $(this).parent().siblings('.form-text.text-muted').show();
    }
  });

  $('body').on('change', '#course_request_first_time', function(e){
    if ($(this).val().length > 0) {
      $(this).siblings('.form-text.text-muted').hide();
    } else {
      $(this).siblings('.form-text.text-muted').show();
    }
  });

  $('body').on('change', '#course_request_user_id', function(e){
    if ($(this).val().length > 0) {
      $(this).siblings('.form-text.text-muted').hide();
      Rails.ajax({
        url: "/profile/user_school_name?user_id=" + $(this).val(),
        type: "GET",
        success: (data) => {
          if (data.school_name) {
            $('#school-field-for-instructor').val(data.school_name);
            $('.js-school-field-instructor').removeClass('hide-content');
          }
        }
      })
    } else {
      $('.js-school-field-instructor').addClass('hide-content');
      $(this).siblings('.form-text.text-muted').show();
    }
  });

   $('body').on('submit', '#js-form-course-request', function(e){
    $('.form-text.text-muted').hide();

    var disableBtn = false;

    if (($('#course_request_user_id').length > 0) &&  ($('#course_request_user_id').val() == '')) {
      $('#course_request_user_id').siblings('.form-text.text-muted').show();
      if (!disableBtn){
        scrollToPoint($('#course_request_user_id'));
      }
      disableBtn = true;
    }

    if ($('#course_request_course_id').val() == '') {
      $('#course_request_course_id').parent().siblings('.form-text.text-muted').show();
      if (!disableBtn){
        scrollToPoint($('#course_request_course_id'))
      }
      disableBtn = true;
    }

    if ($('#course_request_prefix').val() == '') {
      $('#course_request_prefix').parent().siblings('.form-text.text-muted').show();
      if (!disableBtn){
        scrollToPoint($('#course_request_prefix'))
      }
      disableBtn = true;
    }

    if ($('#course_request_number').val() == '') {
      $('#course_request_number').parent().siblings('.form-text.text-muted').show();
      if (!disableBtn){
        scrollToPoint($('#course_request_number'))
      }
      disableBtn = true;
    }

    if ($('#course_request_section').val() == '') {
      if ($('.bootstrap-tagsinput input').length > 0) {
        $('.bootstrap-tagsinput input').parent().siblings('.form-text.text-muted').show();
        if (!disableBtn){
          scrollToPoint($('.bootstrap-tagsinput input'))
        }
      } else {
        $('#course_request_section').parent().siblings('.form-text.text-muted').show();
        if (!disableBtn){
          scrollToPoint($('#course_request_section'))
        }
      }
      disableBtn = true;
    }

    if ($('#course_request_title').val() == '') {
      $('#course_request_title').parent().siblings('.form-text.text-muted').show();
      if (!disableBtn){
        scrollToPoint($('#course_request_title'))
      }
      disableBtn = true;
    }

    if ($('#course_request_semester_id').val() == '') {
      $('#course_request_semester_id').parent().siblings('.form-text.text-muted').show();
      if (!disableBtn){
        scrollToPoint($('#course_request_semester_id'))
      }
      disableBtn = true;
    }

    if ($('#course_request_no_lti_integration').val() == '') {
      $('#course_request_no_lti_integration').parent().siblings('.form-text.text-muted').show();
      if (!disableBtn){
        scrollToPoint($('#course_request_no_lti_integration'))
      }
      disableBtn = true;
    }

    if (($('#course_request_no_lti_integration').val() == 'true') && ($('#course_request_lti_integration').val() == '')) {
      $('#course_request_lti_integration').parent().siblings('.form-text.text-muted').show();
      if (!disableBtn){
        scrollToPoint($('#course_request_lti_integration'))
      }
      disableBtn = true;
    }

    if (($('#course_request_no_lti_integration').val() == 'true') && ($('#course_request_school_lms').val() == '')) {
      $('#course_request_school_lms').parent().siblings('.form-text.text-muted').show();
      if (!disableBtn){
        scrollToPoint($('#course_request_school_lms'))
      }
      disableBtn = true;
    }

    if ($('#course_request_estimated_enrollment').val() == '') {
      $('#course_request_estimated_enrollment').parent().siblings('.form-text.text-muted').show();
      if (!disableBtn){
        scrollToPoint($('#course_request_estimated_enrollment'))
      }
      disableBtn = true;
    }

    if (($('#course_request_no_lti_integration').val() != 'true') && ($('#course_request_date_registration_opens').val() == '')) {
      $('#course_request_date_registration_opens').parent().siblings('.form-text.text-muted').show();
      if (!disableBtn){
        scrollToPoint($('#course_request_date_registration_opens'))
      }
      disableBtn = true;
    }

    if (($('#course_request_no_lti_integration').val() != 'true') && ($('#course_request_date_registration_closes').val() == '')) {
      $('#course_request_date_registration_closes').parent().siblings('.form-text.text-muted').show();
      if (!disableBtn){
        scrollToPoint($('#course_request_date_registration_closes'))
      }
      disableBtn = true;
    }

    if ($('#course_request_date_of_first_class').val() == '') {
      $('#course_request_date_of_first_class').parent().siblings('.form-text.text-muted').show();
      if (!disableBtn){
        scrollToPoint($('#course_request_date_of_first_class'))
      }
      disableBtn = true;
    }

    if ($('#course_request_date_of_last_class').val() == '') {
      $('#course_request_date_of_last_class').parent().siblings('.form-text.text-muted').show();
      if (!disableBtn){
        scrollToPoint($('#course_request_date_of_last_class'))
      }
      disableBtn = true;
    }

    if ($('#course_request_calendar_option').val() == '') {
      $('#course_request_calendar_option').parent().siblings('.form-text.text-muted').show();
      if (!disableBtn){
        scrollToPoint($('#course_request_calendar_option'))
      }
      disableBtn = true;
    }

    if ($('#course_request_first_time').val() == '') {
      $('#course_request_first_time').siblings('.form-text.text-muted').show();
      if (!disableBtn){
        scrollToPoint($('#course_request_first_time'))
      }
      disableBtn = true;
    }
    if (!$('#course_request_no_midterm').is(':checked') && !$('#course_request_midterm_open').is(':checked') && ($('#course_request_date_of_midterm').val() == '')) {
      $('.midterm-exam-error').show();
      if (!disableBtn){
        scrollToPoint($('.midterm-exam-error'))
      }
      disableBtn = true;
    }

    if (!$('#course_request_no_final').is(':checked') && !$('#course_request_final_open').is(':checked') && ($('#course_request_date_of_final').val() == '')) {
      $('.final-exam-error').show();
      if (!disableBtn){
        scrollToPoint($('.final-exam-error'))
      }
      disableBtn = true;
    }

    if (disableBtn) {
      $('.js-client-side-error').show()
      e.preventDefault(e);
      return false;
    }
    $('.js-client-side-error').hide()
  });

  var ticking = false;
  var lastScrollLeft = 0;
  $('#double-scroll').on('scroll', function (e){
    if (!ticking) {
      window.requestAnimationFrame(function() {
          var documentScrollLeft = $('#double-scroll').scrollLeft();
          if (lastScrollLeft != documentScrollLeft) {
            $('table.sticky-headers').stickyTableHeaders({fixedOffset: OffsetValue});
            lastScrollLeft = documentScrollLeft;
          }
          ticking = false;
      });
      ticking = true;
    }
  });

  $('#double-scroll').on('scroll', function (e){
    if ($('.js-offset-top-thead').offset().top < 200) {
      var height = $('.course-request-menu').height();
      $('table.sticky-headers').stickyTableHeaders({fixedOffset: OffsetValue});
    }
  });

  if ($('.student-menu').length > 0) { 
    document.addEventListener('scroll', function (event) {
      if (checkIfScrollApplies) {
        var offset = $('.student-menu').height();
      } else {
        var offset = 0;
      }
      $('table.sticky-headers').stickyTableHeaders({fixedOffset: offset});
    }, true );

    if (checkIfScrollApplies) {
      $('.student-menu').addClass('sticky-search-form');
    }
  }

  $('input.daterange').each(function() {
    $(this).daterangepicker({
      startDate: $(this).data('start-date'),
      endDate: $(this).data('end-date'),
      autoUpdateInput: false,
      locale: {
        cancelLabel: 'Clear'
      }
    });
  });

  $('input.daterange').on('apply.daterangepicker', function(ev, picker) {
    $(this).val(picker.startDate.format('MM/DD/YY') + ' - ' + picker.endDate.format('MM/DD/YY'));
  });
  $('input.daterange').on('cancel.daterangepicker', function(ev, picker) {
    $(this).val('');
  });

  $('.select2').select2({theme: 'bootstrap4', width: 'resolve'});

  $('input.datepicker').datepicker({
    format: 'mm/dd/yy',
    assumeNearbyYear: true
  });

  $("input.phone").usPhoneFormat();
  if ($('#course_request_user_profile_attributes_timezone').data('select2') != undefined) {
    $($('#course_request_user_profile_attributes_timezone').data('select2').$container).find('.select2-selection').addClass('look-disabled');
  }

  $('#checkbox-edit-profile').click(function () {
    var checked = $('#checkbox-edit-profile').is(':checked');
    $($('#course_request_user_profile_attributes_timezone').data('select2').$container).find('.select2-selection').toggleClass('look-disabled', !checked);
    $('.profile-form-part .form-control.js-enable-me').attr('readonly', !checked);
  });

  $('body').on('change', '#switcher-access-code', function(e){
    if ($(this).is(':checked')) {
      $("#via-import").show();
      $("#via-text-box").hide();
    } else {
      $("#via-import").hide();
      $("#via-text-box").show();
    }
  });

  $('body').on('change', "#access_code_file", function(){
    $(this).siblings("label.custom-file-label").text(this.files[0].name);
  });

  if ($('.tinymce-body').length > 0) {
    $("#loader").show();
    (async() => {
      while(!window.hasOwnProperty("tinymce"))
          await new Promise(resolve => setTimeout(resolve, 1000));
      tinymce.remove();
      setTimeout(function () {
        tinymce.init({ selector: '.tinymce-body', plugins: ['link', 'preview'], toolbar: 'link | preview' });
        tinymce.init({ selector: '.tinymce-subject', height: 56, toolbar: '', menubar: false, force_br_newlines : false, force_p_newlines : false, forced_root_block : '' });
        $("#loader").hide();
      }, 2000);
    })();
  }
})

$(document).on('turbolinks:before-cache', function(e) {
  return $('.form-control.select2').each(function() {
    return $(this).select2('destroy');
  });
});

window.addEventListener("trix-file-accept", function(event) {
  event.preventDefault();
  alert("File attachment not supported!");
})

function checkIfScrollApplies() {
  return window.innerWidth > 700;  
}

function scrollToPoint(object) {
  $([document.documentElement, document.body]).animate({
      scrollTop: object.offset().top
    }, 2000);
}

function DoubleScroll(element) {
  var scrollbar = document.createElement('div');
  scrollbar.appendChild(document.createElement('div'));
  scrollbar.style.overflow = 'auto';
  scrollbar.style.overflowY = 'hidden';
  scrollbar.firstChild.style.width = element.scrollWidth+'px';
  scrollbar.classList.add('second-scroll');
  scrollbar.firstChild.style.paddingTop = '1px';
  scrollbar.firstChild.appendChild(document.createTextNode('\xA0'));
  scrollbar.onscroll = function() {
    element.scrollLeft = scrollbar.scrollLeft;
  };
  element.onscroll = function() {
    scrollbar.scrollLeft = element.scrollLeft;
  };
  element.parentNode.insertBefore(scrollbar, element);
}

const application = Application.start();
const context = require.context("../stimulus/controllers", true, /\.js$/);
application.load(definitionsFromContext(context));
application.register("nested-form", NestedForm)
application.register("file-form", AttachmentNestedForm)