import { timers } from "jquery";
import { Controller } from "stimulus";
import $ from 'jquery'
export default class extends Controller {
  static targets = ['container', 'edit', 'submit', 'cancel', 'setActive', 'active', 'editsecond', 'cancelsecond', 'submitsecond'];

  enableEditing(event) {
    let editTarget = this.editTarget;
    let submitTarget = this.submitTarget;
    let cancelTarget = this.cancelTarget;
    let editsecondTarget = this.editsecondTarget;
    let submitsecondTarget = this.submitsecondTarget;
    let cancelsecondTarget = this.cancelsecondTarget;

    // enable fields
    $('input[disabled],select[disabled]').attr('disabled',false);
    $('input[data-disabled="true"]').attr('disabled', true);

    // scrolls to beginning of row, where the form inputs are
    $(this.containerTarget).animate({scrollLeft: 0}, 1000);

    editTarget.hidden = true;
    submitTarget.hidden = false;
    cancelTarget.hidden = false;
    editsecondTarget.hidden = true;
    submitsecondTarget.hidden = false;
    cancelsecondTarget.hidden = false;
  }

  enableEditingSecond(event){
    let editTarget = this.editTarget;
    let submitTarget = this.submitTarget;
    let cancelTarget = this.cancelTarget;
    let editsecondTarget = this.editsecondTarget;
    let submitsecondTarget = this.submitsecondTarget;
    let cancelsecondTarget = this.cancelsecondTarget;

    $('input[disabled],select[disabled]').attr('disabled',false);
    $('input[data-disabled="true"]').attr('disabled', true);

    editTarget.hidden = true;
    submitTarget.hidden = false;
    cancelTarget.hidden = false;
    editsecondTarget.hidden = true;
    submitsecondTarget.hidden = false;
    cancelsecondTarget.hidden = false;
  }

  updateSetActive(event) {
    let active = this.setActiveTarget.checked;

    $("input[data-course-requests-table-target='active']").each(function(){
      if (!$(this).attr('disabled')) {
        $(this).prop('checked', active);
      }
    })
  }

  cancelEditing(event) {
    location.reload();
  }
}