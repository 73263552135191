import Rails from "@rails/ujs"
import { Controller } from "stimulus";
import {error} from "autocomplete.js/src/common/utils";

function validate_email(email) {
  var regex = /^([a-zA-Z0-9._+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  return regex.test(email);
}

function skipObjectIfExists(optionObject, value) {
  return $(optionObject).find('option[value=' + value + ']').length > 0;
}

function validate_phone(phone) {
  return (phone.replace(/[_-]/gi, '').length == 10);
}

function correctDateFormat(date) {
  return date.toLocaleDateString();
  return moment(date).format('MM/DD/YYYY h:mm:ss A');
}

function getDateInterval(from, to) {
  var res = '';
  if (from != null) {
    res += " from " + correctDateFormat(from);
  }

  if (to != null) {
    res += " until " + correctDateFormat(to);
  }
  return res;
}

function addAlert(element, alert, isError = true) {
  let alertClass = (isError ? 'text-danger' : 'text-info');
  let alertHtml = '<small class="'+alertClass+'">' + alert + '</small>';
  $(element).closest('.form-group').find('small').remove();
  $(element).closest('input').after(alertHtml);
  if (isError) {
    $(element).closest('.form-group').addClass('has-error');
  }
}

function addAlertSelect(element, alert, isError = true) {
  let alertClass = (isError ? 'text-danger' : 'text-info');
  let alertHtml = '<small class="'+alertClass+'">' + alert + '</small>';
  $(element).closest('.form-group').find('small').remove();
  $(element).closest('select').after(alertHtml);
  if (isError) {
    $(element).closest('.form-group').addClass('has-error');
  }
}

function removeAlert(element) {
  $(element).closest('.form-group').removeClass('has-error');
  $(element).closest('.form-group').find('small').remove();
}

export default class extends Controller {
  static targets = ["school", "instructor", "semester", "course", "group", "accessCode", "username",
                    "password", "confirmPassword", "firstName", "lastName", "email", "confirmEmail", "phone", "terms", "submit",
                    "verificationContainer", "sendCodeButton", "sendCodeResult", "emailCode"];

  initialize() {
  }

  updateTerms(event) {
    let termsTarget = this.termsTarget;

    if (termsTarget.checked) {
      $(termsTarget).parent().siblings('.form-text.text-muted').hide();
    } else {
      $(termsTarget).parent().siblings('.form-text.text-muted').show();
    }
  }

  updatePhone(event) {
    let phoneTarget = this.phoneTarget;
    let termsTarget = this.termsTarget;
    let submitTarget = this.submitTarget;

    if (validate_phone(phoneTarget.value)) {
      $(phoneTarget).siblings('.form-text.text-muted').hide();
      termsTarget.disabled = false;
      submitTarget.disabled = false;
    } else {
      $(phoneTarget).siblings('.form-text.text-muted').show();
      termsTarget.disabled = true;
      submitTarget.disabled = true;
    }
  }

  updateConfirmEmail(event) {
    let emailTarget = this.emailTarget;
    let confirmEmailTarget = this.confirmEmailTarget;
    let phoneTarget = this.phoneTarget;
    let submitTarget = this.submitTarget;

    if (validate_email(emailTarget.value) && emailTarget.value == confirmEmailTarget.value) {
      phoneTarget.disabled = false;
      $(confirmEmailTarget).siblings('.form-text.text-muted').hide();
    } else {
      phoneTarget.disabled = true;
      submitTarget.disabled = true;
      $(confirmEmailTarget).siblings('.form-text.text-muted').show();
    }
  }

  updateEmail(event) {
    let emailTarget = this.emailTarget;
    let confirmEmailTarget = this.confirmEmailTarget;

    let locked = $(emailTarget).data('locked') == '1';

    if (validate_email(emailTarget.value) && !locked) {
      confirmEmailTarget.disabled = false;
    } else {
      confirmEmailTarget.disabled = true;
    }
  }

  updateLastName(event) {
    let lastNameTarget = this.lastNameTarget;
    let emailTarget = this.emailTarget;
    let confirmEmailTarget = this.confirmEmailTarget;
    let phoneTarget = this.phoneTarget;
    let submitTarget = this.submitTarget;

    if (lastNameTarget.value.length >= 1) {
      emailTarget.disabled = false;
    } else {
      emailTarget.disabled = true;
      confirmEmailTarget.disabled = true;
      phoneTarget.disabled = true;
      submitTarget.disabled = true;
    }
  }

  updateFirstName(event) {
    let firstNameTarget = this.firstNameTarget;
    let lastNameTarget = this.lastNameTarget;
    let emailTarget = this.emailTarget;
    let confirmEmailTarget = this.confirmEmailTarget;
    let phoneTarget = this.phoneTarget;
    let submitTarget = this.submitTarget;

    if (firstNameTarget.value.length >= 1) {
      lastNameTarget.disabled = false;
    } else {
      lastNameTarget.disabled = true;
      emailTarget.disabled = true;
      confirmEmailTarget.disabled = true;
      phoneTarget.disabled = true;
      submitTarget.disabled = true;
    }
  }

  updateConfirmPassword(event) {
    let passwordTarget = this.passwordTarget;
    let confirmPasswordTarget = this.confirmPasswordTarget;
    let firstNameTarget = this.firstNameTarget;
    let lastNameTarget = this.lastNameTarget;
    let emailTarget = this.emailTarget;
    let confirmEmailTarget = this.confirmEmailTarget;
    let phoneTarget = this.phoneTarget;
    let submitTarget = this.submitTarget;

    if (confirmPasswordTarget.value.length > 0) {
      $(confirmPasswordTarget).siblings('.input-group-append').show();
      if ($('#student_confirm_password').attr('type') == 'text') {
        $('#c-password-visibility-true').addClass("d-none");
        $('#c-password-visibility-false').removeClass("d-none");
      } else {
        $('#c-password-visibility-true').removeClass("d-none");
        $('#c-password-visibility-false').addClass("d-none");
      }
    } else {
      $(confirmPasswordTarget).siblings('.input-group-append').hide();
      $('#c-password-visibility-true').addClass("d-none");
      $('#c-password-visibility-false').addClass("d-none");
    }

    if (passwordTarget.value.length >= 8 && passwordTarget.value == confirmPasswordTarget.value) {
      firstNameTarget.disabled = false;
      $(confirmPasswordTarget).parent().siblings('.form-text.text-muted').hide();
    } else {
      $(confirmPasswordTarget).parent().siblings('.form-text.text-muted').show();
      firstNameTarget.disabled = true;
      lastNameTarget.disabled = true;
      emailTarget.disabled = true;
      confirmEmailTarget.disabled = true;
      phoneTarget.disabled = true;
      submitTarget.disabled = true;
    }
  }

  updatePassword(event) {
    let passwordTarget = this.passwordTarget;
    let confirmPasswordTarget = this.confirmPasswordTarget;
    let firstNameTarget = this.firstNameTarget;
    let lastNameTarget = this.lastNameTarget;
    let emailTarget = this.emailTarget;
    let confirmEmailTarget = this.confirmEmailTarget;
    let phoneTarget = this.phoneTarget;
    let submitTarget = this.submitTarget;

    if (passwordTarget.value.length > 0) {
      $(passwordTarget).siblings('.input-group-append').show();
      if ($('#student_password').attr('type') == 'text') {
        $('#password-visibility-true').addClass("d-none");
        $('#password-visibility-false').removeClass("d-none");
      } else {
        $('#password-visibility-true').removeClass("d-none");
        $('#password-visibility-false').addClass("d-none");
      }
    } else {
      $(passwordTarget).siblings('.input-group-append').hide();
      $('#password-visibility-true').addClass("d-none");
      $('#password-visibility-false').addClass("d-none");
    }

    if (passwordTarget.value.length >= 8) {
      confirmPasswordTarget.disabled = false;
      $(confirmPasswordTarget).click();
      $(passwordTarget).parent().siblings('.form-text.text-muted').hide();
    } else {
      confirmPasswordTarget.disabled = true;
      firstNameTarget.disabled = true;
      lastNameTarget.disabled = true;
      emailTarget.disabled = true;
      confirmEmailTarget.disabled = true;
      phoneTarget.disabled = true;
      submitTarget.disabled = true;
      $(passwordTarget).parent().siblings('.form-text.text-muted').show();
    }
  }

  updateUsername(event) {
    let usernameTarget = this.usernameTarget;
    let passwordTarget = this.passwordTarget;
    let confirmPasswordTarget = this.confirmPasswordTarget;
    let firstNameTarget = this.firstNameTarget;
    let lastNameTarget = this.lastNameTarget;
    let emailTarget = this.emailTarget;
    let confirmEmailTarget = this.confirmEmailTarget;
    let phoneTarget = this.phoneTarget;
    let submitTarget = this.submitTarget;

    if (usernameTarget.value.length >= 5) {
      passwordTarget.disabled = false;
    } else {
      passwordTarget.disabled = true;
      confirmPasswordTarget.disabled = true;
      firstNameTarget.disabled = true;
      lastNameTarget.disabled = true;
      emailTarget.disabled = true;
      confirmEmailTarget.disabled = true;
      phoneTarget.disabled = true;
      submitTarget.disabled = true;
    }
  }

  updateEmailCode(event) {
    let emailCodeTarget = this.emailCodeTarget;
    let phoneTarget = this.phoneTarget;

    if (emailCodeTarget.value.length == 10) {
      phoneTarget.disabled = false;
    }
    else {
      phoneTarget.disabled = true;
    }
  }

  sendCode(event) {
    let emailTarget = this.emailTarget;
    let usernameTarget = this.usernameTarget;
    let sendCodeResultTarget = this.sendCodeResultTarget;
    let emailCodeTarget = this.emailCodeTarget;

    if (validate_email(emailTarget.value)) {
      let data = new FormData();
      data.append('username', usernameTarget.value);
      data.append('email', emailTarget.value);

      Rails.ajax({
        url: '/email_verification_codes/send_code',
        type: "POST",
        data: data,
        success: (data) => {
          if (data.success) {
            sendCodeResultTarget.innerHTML = "Verification code has been sent to the email address associated with your account";
            emailCodeTarget.disabled = false
          } else {
            sendCodeResultTarget.innerHTML = "This email address is not associated with your account. Please contact support at <a href=\"mailto:support@connect4education.com\">support@connect4education.com</a>";
            emailCodeTarget.disabled = true
          }
        }
      })
    }

    event.preventDefault();
  }

  checkEmail(event) {
    let usernameTarget = this.usernameTarget;
    let emailTarget = this.emailTarget;
    let confirmEmailTarget = this.confirmEmailTarget;
    let phoneTarget = this.phoneTarget;
    let submitTarget = this.submitTarget;

    let existingUser = $(usernameTarget).data('existing-user');
    $(emailTarget).siblings('.form-text.text-muted').hide();

    if (!existingUser && validate_email(emailTarget.value)) {
      let data = new FormData();
      data.append('field', 'email');
      data.append('value', emailTarget.value);

      Rails.ajax({
        url: '/api/check_moodle_field',
        type: "POST",
        data: data,
        success: (data) => {
          if (data.exists) {
            let alert = 'This email is already associated with an account under a different username';
            let alertHtml = '<small class="text-danger">' + alert + '</small>';
            $(emailTarget).closest('.form-group').find('small.text-danger').remove();
            $(emailTarget).closest('input').after(alertHtml);
            $(emailTarget).closest('.form-group').addClass('has-error');
            confirmEmailTarget.disabled = true;
            phoneTarget.disabled = true;
            submitTarget.disabled = true;
          } else {
            $(emailTarget).closest('.form-group').removeClass('has-error');
            $(emailTarget).closest('.form-group').find('small.text-danger').remove();
            confirmEmailTarget.disabled = false;
            $(confirmEmailTarget).click();
          }
        }
      })
    } else {
      if (!existingUser){
        $(emailTarget).siblings('.form-text.text-muted').show();
      } else {
        $(emailTarget).siblings('.form-text.text-muted').hide();
      }
      confirmEmailTarget.disabled = true;
      phoneTarget.disabled = true;
      submitTarget.disabled = true;
    }
  }

  checkUsername(event) {
    let usernameTarget = this.usernameTarget;
    let emailTarget = this.emailTarget;
    let verificationContainerTarget = this.verificationContainerTarget;

    let data = new FormData();
    usernameTarget.value = usernameTarget.value.toLocaleLowerCase().replace(/[^a-z0-9]/gi,'');
    data.append('field', 'username');
    data.append('value', usernameTarget.value);

    if (usernameTarget.value.length >= 5) {
      $(usernameTarget).siblings('.form-text.text-muted').hide();
      Rails.ajax({
        url: '/api/check_moodle_field',
        type: "POST",
        data: data,
        success: (data) => {
          if (data.exists) {
            let errorMessage = data.error_msg;
            let alertHtml = '<small class="text-info">' + errorMessage + '</small>';
            $(usernameTarget).closest('.form-group').find('small.text-info').remove();
            $(usernameTarget).closest('input').after(alertHtml);
            if (errorMessage.indexOf('Username already exists') > -1){
              verificationContainerTarget.hidden = false;
              emailTarget.disabled = false;
              $(usernameTarget).data('existing-user', true);
              $('[data-new-user-field="1"]').each(function() {
                $(this).closest('.form-group').hide();
                $(this).prop('required', false);
              });
            }
          } else {
            $(usernameTarget).closest('.form-group').removeClass('has-error');
            $(usernameTarget).closest('.form-group').find('small.text-info').remove();
            verificationContainerTarget.hidden = true;
            emailTarget.disabled = true;
            $(usernameTarget).data('existing-user', false);

            $('[data-new-user-field="1"]').each(function() {
              $(this).closest('.form-group').show();
              $(this).prop('required', true);
            });
          }
        }
      })
    } else {
      $(usernameTarget).closest('.form-group').removeClass('has-error');
      $(usernameTarget).closest('.form-group').find('small.text-info').remove();
      verificationContainerTarget.hidden = true;
      emailTarget.disabled = true;
      $(usernameTarget).data('existing-user', false);

      $('[data-new-user-field="1"]').each(function() {
        $(this).closest('.form-group').show();
        $(this).prop('required', true);
      });
      $(usernameTarget).siblings('.form-text.text-muted').show();
    }
  }

  updateAccessCode(event) {
    let accessCodeTarget = this.accessCodeTarget;
    if (accessCodeTarget.value.length < 3) {
      return;
    }
    let courseTarget = this.courseTarget;
    let usernameTarget = this.usernameTarget;
    let passwordTarget = this.passwordTarget;
    let confirmPasswordTarget = this.confirmPasswordTarget;
    let firstNameTarget = this.firstNameTarget;
    let lastNameTarget = this.lastNameTarget;
    let emailTarget = this.emailTarget;
    let confirmEmailTarget = this.confirmEmailTarget;
    let phoneTarget = this.phoneTarget;
    let submitTarget = this.submitTarget;

    let data = new FormData();
    data.append('access_code', accessCodeTarget.value);
    data.append('course_request_id', courseTarget.value);

    Rails.ajax({
      url: '/access_codes/verify',
      type: "POST",
      data: data,
      success: (data) => {
        if (!data.valid) {
          if (data.access_code_error) {
            addAlert(accessCodeTarget, data.access_code_error);
            usernameTarget.disabled = true;
            passwordTarget.disabled = true;
            confirmPasswordTarget.disabled = true;
            firstNameTarget.disabled = true;
            lastNameTarget.disabled = true;
            emailTarget.disabled = true;
            confirmEmailTarget.disabled = true;
            phoneTarget.disabled = true;
            submitTarget.disabled = true;
          }
        } else {
          removeAlert(accessCodeTarget);
          usernameTarget.disabled = false;
        }
      }
    })
  }

  updateSchool(event) {
    let school = this.schoolTarget.value;
    let instructor = this.instructorTarget;
    instructor.value = null;

    if (school == ''){
      $('#student_student_enrollment_school_id').siblings('.form-text.text-muted').show();
    } else {
      $('#student_student_enrollment_school_id').siblings('.form-text.text-muted').hide();
    }

    this.clearSemester()
    this.clearCourse()
    this.clearInstructor()

    let instructors = select_data.filter(function(el){ return el[3][1] == school })
    var opt = document.createElement('option');
    opt.value = '';
    opt.innerHTML = "Select Instructor...";
    instructor.appendChild(opt);
    instructors.forEach(function(el){
      if (skipObjectIfExists(instructor, el[1][1])) return;
      var opt = document.createElement('option');
      opt.value = el[1][1];
      opt.innerHTML = el[1][0];
      instructor.appendChild(opt);
    })
    $('.lti-course-message').hide();

    instructor.disabled = !school;
  }


  updateInstructor(event) {
    let semester = this.semesterTarget;
    let instructor = this.instructorTarget.value;
    let school = this.schoolTarget.value;

    this.clearSemester()
    this.clearCourse()


    let semesters = select_data.filter(function(el){ return el[3][1] == school && el[1][1] == instructor })
    var opt = document.createElement('option');
    opt.value = '';
    opt.innerHTML = "Select Semester...";
    semester.appendChild(opt);
    semesters.forEach(function(el){
      if (skipObjectIfExists(semester, el[2][1])) return;
      var opt = document.createElement('option');
      opt.value = el[2][1];
      opt.innerHTML = el[2][0];
      semester.appendChild(opt);
    })
    $('.lti-course-message').hide();
    $('.registration-date-message').hide();

    semester.disabled = !instructor;
  }

  updateSemester(event) {
    let course = this.courseTarget;
    let semester = this.semesterTarget.value;
    let instructor = this.instructorTarget.value;
    let school = this.schoolTarget.value;

    this.clearCourse()

    let courses = select_data.filter(function(el){ return el[3][1] == school && el[1][1] == instructor && el[2][1] == semester  })
    var opt = document.createElement('option');
    opt.value = '';
    opt.innerHTML = "Select Course...";
    course.appendChild(opt);
    courses.forEach(function(el){
      var opt = document.createElement('option');
      opt.value = el[0][1];
      opt.innerHTML = el[0][6].concat("-", el[0][7], "-", el[0][8], "-", el[0][0]);
      course.appendChild(opt);
    })
    $('.lti-course-message').hide();
    $('.registration-date-message').hide();

    course.disabled = !semester;

  }

  updateCourse(event){
    removeAlert(this.courseTarget);
    let course = this.courseTarget.value;
    if (!course) {
      $('.js-course-group-id').hide()
      $('.lti-course-message').hide();
      $('.registration-details').hide();
      $('.registration-date-message').hide();
      return

    }
    let accessCodeTarget = this.accessCodeTarget;
    let groupTarget = this.groupTarget;
    let course_value = select_data.filter(function(el){ return el[0][1] == course })[0];

    let start_date = new Date(course_value[0][3].replace(/\-/g,'\/').replace(/[T|Z]/g,' '));
    let end_date = new Date((new Date(course_value[0][4].replace(/\-/g,'\/').replace(/[T|Z]/g,' '))).setHours(23,59,59,999));
    let course_end_date = new Date(course_value[0][5].replace(/\-/g,'\/').replace(/[T|Z]/g,' '));
    let current_date = new Date();
    let isSuccess = true;
    let errorMessage = ''
    let startValid = course_value[0][9] === "true"
    let endValid = course_value[0][10] === "true"

    // uses constant allowLate declared in app/views/students/new.html.erb
    if (!allowLate) {
      if (!startValid) {
        isSuccess = false;
        errorMessage = 'The registration period for this course has not started yet. The registration period runs' + getDateInterval(start_date, end_date) + '. Should you require further assistance, please contact your instructor';
      } else if (!endValid) {
        isSuccess = false;
        errorMessage = 'The registration period for this course has ended. The registration period runs' + getDateInterval(start_date, end_date) + '. Should you require further assistance, please contact your instructor.';
      }
    }

    if (isSuccess) {
      if (course_value[0][2] == 'no_lti' || course_value[0][2] == ''){
        $('.lti-course-message').hide();
        let data = new FormData();
        $("#loader").show()
        Rails.ajax({
          url: "/students/associated_course_groups?course_request_id=" + course,
          type: "GET",
          success: (data) => {
            $("#loader").hide()
            if (data.valid) {
              $('.registration-details').show();
              if (data.groups_ids.length > 0) {
                $('.js-course-group-id').css('display', 'flex')
                $(groupTarget).empty();
                $(groupTarget).append($('<option>', { value: '', text : 'Select Group...' }));
                $.each(data.groups_ids, function (i, item) {
                  $(groupTarget).append($('<option>', {
                    value: item[0],
                    text : item[1] 
                  }));
                });
                groupTarget.disabled = false;
                accessCodeTarget.disabled = true;
              } else {
                $('.js-course-group-id').hide()
                groupTarget.disabled = true;
                accessCodeTarget.disabled = false;
              }
            } else {
              $('.js-course-group-id').hide()
              groupTarget.disabled = true;
              addAlertSelect(this.courseTarget, data.associated_course_groups_error);
              accessCodeTarget.disabled = true;
            }
          }
        })
      }
      else {
        $('.js-course-group-id').hide()
        $('.lti-course-message').show();
        $('.registration-details').hide();
      }
      $('.registration-date-message').hide();
    } else {
      if (course_value[0][2] == 'deep_link' || course_value[0][2] == 'simple'){
        $('.lti-course-message').show();
        $('.registration-date-message').hide();
      } else {
        $('.registration-date-message').html(errorMessage);
        $('.lti-course-message').hide();
        $('.registration-date-message').show();
      }
      $('.registration-details').hide();
      $('.js-course-group-id').hide();
    }
  }

  updateGroup(event){
    let accessCodeTarget = this.accessCodeTarget;
    let group = this.groupTarget.value;
    $('.registration-details').show();
    accessCodeTarget.disabled = !group;
  }

  clearInstructor(){
    let school = this.schoolTarget.value;
    let instructor = this.instructorTarget;
    instructor.value = '';
    instructor.disabled = true;
    instructor.innerHTML = '';
    return true;
  }

  clearSemester(){
    let instructor = this.instructorTarget;
    let semester = this.semesterTarget;
    semester.value = null;
    semester.disabled = true;
    semester.innerHTML = '';
  }

  disableRemainingFields() {
    $('.registration-details input').attr('disabled', 'disabled');
  }

  clearCourse(){
    let semester = this.semesterTarget;
    let course = this.courseTarget;
    let group = this.groupTarget;
    course.value = null;
    course.disabled = true;
    course.innerHTML = '';
    group.value = null;
    group.disabled = true;
    group.innerHTML = '';
    this.disableRemainingFields();
  }
}
