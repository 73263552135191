import { Controller } from "stimulus";
require("bootstrap-datepicker")

export default class extends Controller {
  static targets = ["phone", "dates", "lti", "midterm", "final", "no_midterm", "midterm_open", "no_final", "final_open",
    "midterm_dates", "final_dates", "lms", "calendar_option", "calendar_option_text"];

  initialize() {
    this.updateLMS();
    this.updateCalendarOption();
    this.updateMidterm();
    this.updateMidtermOpen();
    this.updateFinal();
    this.updateFinalOpen();
  }

  updateMidterm(event) {
    let midterm = this.no_midtermTarget.checked;
    if(midterm){
      this.midtermTarget.classList.add('disable_block')
    }else{
      this.midtermTarget.classList.remove('disable_block')
    }
  }

  updateMidtermOpen(event) {
    let midterm = this.midterm_openTarget.checked;
    if(midterm){
      this.midterm_datesTarget.classList.add('disable_block')
    }else{
      this.midterm_datesTarget.classList.remove('disable_block')
    }
  }

  updateFinal(event) {
    let final_ex = this.no_finalTarget.checked;
    if(final_ex){
      this.finalTarget.classList.add('disable_block')
    }else{
      this.finalTarget.classList.remove('disable_block')
    }
  }

  updateFinalOpen(event) {
    let final_ex = this.final_openTarget.checked;
    if(final_ex){
      this.final_datesTarget.classList.add('disable_block')
    }else{
      this.final_datesTarget.classList.remove('disable_block')
    }
  }

  updatePhone(event) {
    let phoneTarget = this.phoneTarget;
    
    if (phoneTarget.value.replace(/[_-]/gi, '').length == 10) {
      $('.form-text.text-muted').hide();
      $('#checkbox-edit-profile').attr('disabled', false);
    } else {
      $('.form-text.text-muted').show();
      $('#checkbox-edit-profile').attr('disabled', true);
    }
  }

  updateLMS(event) {
    if(this.ltiTarget.selectedIndex == 2){
      this.lmsTarget.classList.remove('d-none')
      this.lmsTarget.querySelectorAll('label').forEach(label => label.classList.add('required'));
    } else {
      this.lmsTarget.classList.add('d-none');
      this.lmsTarget.querySelectorAll('label').forEach(label => label.classList.remove('required'));
      this.lmsTarget.querySelectorAll('select').forEach(select => select.selectedIndex = 0);
    }
  }
  updateCalendarOption(event) {
    if(this.calendar_optionTarget.selectedIndex == 2){
      this.calendar_option_textTarget.classList.remove('d-none')
    } else {
      this.calendar_option_textTarget.classList.add('d-none');
    }
  }
  initDatePicker(event) {
      $('input.datepicker').datepicker({
        format: 'mm/dd/yy'
      });
  }
}