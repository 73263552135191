import NestedForm from "stimulus-rails-nested-form"


export default class extends NestedForm {
  initialize() {
    super.initialize()
    this.showFileName()
  }

  showFileName() {
    const courseFiles = document.querySelector("#course_files");
    courseFiles.addEventListener("change", (e) => {
      const content = `<a href="javascript:void(0)">${e.target.files[0].name}</a>&nbsp;&nbsp;`;
      let btn = e.target.closest('[data-new-record]').querySelector('[data-action="file-form#remove"]');
      if (btn.previousElementSibling.tagName.toLowerCase() == 'a') {
        btn.previousElementSibling.remove();
      }
      btn.insertAdjacentHTML('beforebegin', content);
    })
  }
}